import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserDetails from '../Form/index.jsx';
import SpinnerWheel from '../SpinnerWheel/index.jsx';

const validation = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Enter a valid email'),
    name: Yup.string().required('Name is required'),
});

const MainContainer = () => {

    const [isSubmit, setIsSubmit] = useState(false)
    const [userInfo, setUserInfo] = useState({ email: '', name: '' })

    const handleSubmit = async (values) => {
        setUserInfo(values)
        setIsSubmit(true)
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validation,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    return (
        <>
            {!isSubmit ?
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <UserDetails formik={formik} />
                </form> : <SpinnerWheel userInfo={userInfo} />
            }
        </>

    )
}

export default MainContainer