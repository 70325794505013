import React from 'react'
import styled from '@emotion/styled';
import FormImage from '../assets/formImage.png'
import ZedaLogo from '../assets/zeda_logo.png'

const Container = styled.div`
padding-left: 48px;
padding-top: 16px;
height: calc(100vh - 16px);
overflow: auto;
@media (max-width:528px) {
    padding-left: 16px;
    padding-right: 16px;
}
`;

const MainContentContainer = styled.div`
justify-content: center;
display: flex;
align-items: center;
height: calc(100% - 200px);
margin-left: 32px;
margin-top: 60px;
@media (max-width:1094px) {
    margin-top: 60px;
}
@media (max-width:528px) {
    margin-left: 0px;
}
`;

const TextContainer = styled.div`
margin-right: 16%;
@media (max-width:528px) {
    margin-right: 0px;
}
`;

const Line1 = styled.span`
color: #344054;
font-size: 48px;
font-weight: 500;
@media (max-width:1094px) {
    font-size: 32px;
}
`;

const Line2 = styled.span`
color: #3F57DE;
font-size: 48px;
font-weight: 500;
@media (max-width:1094px) {
    font-size: 32px;
}
`;

const Line3 = styled.span`
color: #667085;
font-size: 20px;
font-weight: 400;
display: block;
margin-top: 24px;
@media (max-width:1094px) {
    font-size: 16px;
}
`;

const NameText = styled.span`
color: #344054;
font-size: 14px;
font-weight: 500;
display: block;
margin-top: 24px;
@media (max-width:528px) {

 }
`;

const EmailText = styled.span`
color: #344054;
font-size: 14px;
font-weight: 500;
display: block;
margin-top: 16px;
@media (max-width:528px) {
    width: 228px;
    margin-right: 80px;
 }
`;

const StyledInput = styled.input`
padding: 0px 14px 0px 14px;
border: 1px solid #D0D5DD;
border-radius: 8px;
width: 320px;
margin-top: 5px;
height: 44px;
::placeholder {
    color: #667085;
}

:hover,
	:focus,
	:focus-visible {
		border: 1px solid #8594EA;
        outline: none;
	}
   
@media (max-width:528px) {
   width: 92%;
}
`;

const ButtonContainer = styled.div`
@media (max-width:528px) {
    display: flex;
    align-items: center;
    justify-content: center;
 }


`

const PrimaryButton = styled.button`
width: 350px;
height: 40px;
margin-top: 32px;
background-color: #5E72E4;
color: white;
border-radius: 8px;
border: 1px solid transparent;
cursor: pointer;
font-size: 14px;
font-weight: 500;
display: block;

:disabled {
    background-color: rgb(186, 195, 243);
    pointer-events: none;
}
@media (max-width:551) {
    margin-bottom: 24px;
}

`;

const ErrorText = styled.span`
letter-spacing: -0.02em;
font-size: 12px;
font-weight: 400;
background-color: transparent;
color: rgb(240, 68, 56);
display: ${(props) => (props.show ? 'block' : 'none')};
margin-top: 2px;
`;

const StyledFormImage = styled.img`
width: 540px;
height: 490px;
@media (max-width:1094px) {
   height: 400px;
}

@media (max-width:1025px) {
    display: none;
 }

`;

const Line4 = styled.span`
color: #344054;
font-size: 20px;
font-weight: 500;
display: block;
margin-top: 24px;
@media (max-width:556px) {
    font-size: 16px;
}
@media (max-width:412px) {
    font-size: 14px;
}
`;

const Line5 = styled.span`
color: #344054;
font-size: 18px;
font-weight: 400;
display: block;
margin-top: 24px;
@media (max-width:556px) {
    font-size: 14px;
}
@media (max-width:412px) {
    font-size: 12px;
}
`;

const UserDetails = (props) => {
    const { formik } = props;

    const isNameError = formik.errors.name && formik.touched.name !== undefined;
    const isEmailError = formik.errors.email && formik.touched.email !== undefined;

    return (
        <Container>
            <a href='https://zeda.io/' target="_blank" rel="noreferrer">
                <img
                    src={ZedaLogo}
                    alt="ZedaLogo"
                    height={46}
                    width={120}
                    margin={{ top: 16, left: 16, bottom: 8 }}

                />
            </a>
            <MainContentContainer>
                <div>
                    <TextContainer>
                        <Line1>The Great</Line1>
                        <Line2> PM Skill-off </Line2>
                        <Line3>Product Manager at any given point wears multiple hats, spin to see which one hits home and get a chance to win the Jackpot! ✨</Line3>
                        <Line4>Which hat are you wearing today??? 🎩</Line4>
                        <Line5>Tell us about yourself</Line5>
                    </TextContainer>
                    <NameText>Name</NameText>
                    <StyledInput name='name' placeholder="Enter name" value={formik.values.name}
                        onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <ErrorText show={isNameError}>
                        {formik.errors.name}
                    </ErrorText>
                    <EmailText>Email</EmailText>
                    <StyledInput name='email' placeholder="Enter email" value={formik.values.email}
                        onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    <ErrorText show={isEmailError}>
                        {formik.errors.email}
                    </ErrorText>
                    <ButtonContainer>
                        <PrimaryButton disabled={!!(isNameError || isEmailError)}
                            onClick={formik.handleSubmit}
                            type="submit" >Proceed</PrimaryButton>
                    </ButtonContainer>

                </div>
                <StyledFormImage src={FormImage} />
            </MainContentContainer>
        </Container>
    )
}

export default UserDetails