import React from 'react'
import Modal from './Modal'
import styled from '@emotion/styled';

const StyledModal = styled.div`
padding: 24px;
@media (max-width:750px) {
    padding: 16px;   
}
`;

const HeaderText = styled.span`
font-size: 22px;
font-weight: 700;
color: #101828;
display: block;
width: 96%;
@media (max-width:750px) {
    font-size: 18px; 
}
`;

const Description = styled.span`
display: block;
font-size: 20px;
font-weight: 400;
color: #344054;
margin-top: 20px;
@media (max-width:750px) {
    font-size: 14px;
    
}
`;

const SecondaryButton = styled.button`
padding-top: 8px;
padding-bottom: 8px;;
padding-left: 12px;
padding-right: 12px;
color: #3F57DE;
border: 1px solid #A8B3F0;
margin-top: 24px;
border-radius: 8px;
background-color: white;
height: 40px;
cursor: pointer;
font-size: 16px;
@media (max-width:750px) {
    font-size: 14px;
    
}
`;

const CommonContent = styled.div`
margin-top: 20px;
font-size: 20px;
@media (max-width:750px) {
    font-size: 14px;
    
}

`;

const FreeMonth = styled.div`
color: #1D2939;
font-size: 20px;
font-weight: 500;
margin-top: 20px;

@media (max-width:750px) {
    font-size: 18px;  
}
`;

const JackpotPoint1 = styled.div`
margin-top: 20px;
font-size: 20px;
@media (max-width:750px) {
    font-size: 14px;  
}
`;

const JackpotOtherPoints = styled.div`
margin-top: 16px;
font-size: 20px;
@media (max-width:750px) {
    font-size: 14px;  
}
`;

const JackpotInfo = styled.div`
margin-top: 16px;
margin-bottom: 20px;
font-size: 20px;
@media (max-width:750px) {
    font-size: 14px;  
}
`;

const PrizeName = styled.span`
font-size: 24px;
margin-bottom: 20px;
font-weight: 700;
color: #101828;
display: block;
@media (max-width:750px) {
    font-size: 18px; 
}
`;


const ResultModal = (props) => {

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} width='75%' maxWidth={600} hasCloseIcon disableBackDropClick backdrop>
            <StyledModal>
                <PrizeName>{props.content.option}</PrizeName>
                <HeaderText>{props.content.title}</HeaderText>
                <CommonContent>💜 You’ll love Zeda.io because,</CommonContent>
                {props.content.title !== 'You build products that solve real problems. 🚀' ? <> <Description>{props.content.description}</Description>
                    <FreeMonth>Get 3 months free on Zeda.io 💜 </FreeMonth>
                    <a href='https://hubs.la/Q01RshTH0' target="_blank" rel="noreferrer">
                        <SecondaryButton>Schedule a Walkthrough</SecondaryButton>
                    </a>
                </> : <>
                    <JackpotPoint1>✅ Gather Customer Insights → Uncover problems to solve for customers</JackpotPoint1>
                    <JackpotOtherPoints>✅ Build with Product Intelligence → Decide what to build next and why.</JackpotOtherPoints>
                    <JackpotOtherPoints>✅ Measure Strategy Impact → Create product strategies & measure outcomes.</JackpotOtherPoints>
                    <JackpotInfo>Leverage the power of AI to craft Impactful Products.</JackpotInfo>
                    <FreeMonth>Get 6 months free on Zeda.io 💜 </FreeMonth>
                    <a href='https://hubs.la/Q01RshTH0' target="_blank" rel="noreferrer">
                        <SecondaryButton>Redeem the offer</SecondaryButton>
                    </a>
                </>}
            </StyledModal>
        </Modal >
    )
}

export default ResultModal