import React from 'react'
import MainContainer from './MainContainer'


const App = () => {
  return (
   <MainContainer />
  )
}

export default App