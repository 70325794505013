import React from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import Xmark from '../assets/xmark.svg'

export const BackDrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background: ${({ backdrop }) => (backdrop ? `rgba(52, 64, 84, 0.7)` : `transparent`)};
	${({ blurBackdrop }) => blurBackdrop && `backdrop-filter: blur(4px);`}
`;

const ContentBody = styled.div`
	position: fixed;
	background-color: white;
	z-index: 1050;
	border-radius: 12px;
	overflow: hidden;
	${({ showBoxShadow }) =>
        showBoxShadow &&
        `box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);`}
	${({ width }) => width && `width: ${typeof width === 'number' ? `${width}px` : width};`}
	${({ height }) => height && `height: ${typeof height === 'number' ? `${height}px` : height};`}
	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
	${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
	${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
	${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
	${({ isCenter = true, top, left, right, bottom }) =>
        isCenter
            ? `
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`
            : `
		${top ? `top: ${top}` : ''};
		${left ? `left: ${left}` : ''};
		${right ? `right: ${right}` : ''};
		${bottom ? `bottom: ${bottom}` : ''};
	`}

    @media (max-width:750px) {
        width: 88%;
    }
  
`;

const ModalDiv = styled.div`
	position: absolute;
	z-index: 1050;
    
`;

const CrossIcon = styled.img`
position: absolute;
top: 18px;
right: 16px;
cursor: pointer;

`;

export const ModalCloseButton = (props) => (
    <CrossIcon
        className="modal-close-button"
        onClick={props.toggle}
        src={Xmark}
    />
);

const Modal = (props) => {
    const {
        isOpen,
        toggle,
        children,
        isCenter,
        top,
        left,
        backdrop = true,
        className = '',
        width,
        height,
        maxHeight,
        minHeight,
        maxWidth,
        minWidth,
        padding,
        margin,
        border,
        disableBackDropClick = false,
        showBoxShadow = true,
        hasCloseIcon = true,
        modalContainerClassName,
        ...rest
    } = props;

    const bodyElement = document.body;

    const backDropClick = () => {
        if (disableBackDropClick) {
            return;
        }
        toggle();
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalDiv className={modalContainerClassName} onClick={backDropClick}>
            <ContentBody
                className={className}
                onClick={(e) => e.stopPropagation()}
                isCenter={isCenter}
                top={top}
                left={left}
                width={width}
                height={height}
                maxHeight={maxHeight}
                minHeight={minHeight}
                maxWidth={maxWidth}
                minWidth={minWidth}
                padding={padding}
                margin={margin}
                border={border}
                showBoxShadow={showBoxShadow}
                {...rest}
            >
                <div>
                    {hasCloseIcon && <ModalCloseButton toggle={toggle} />}
                    {children}
                </div>
            </ContentBody>
            {backdrop && (
                <BackDrop className="backdrop" blurBackdrop={disableBackDropClick} backdrop={backdrop} />
            )}
        </ModalDiv>,
        bodyElement
    );
};

export default Modal;
