import React, { useState, useEffect } from 'react'
import { Wheel } from 'react-custom-roulette'
import wheelSound from '../assets/wheel-spin.wav'
import ZedaIcon from '../assets/zeda_logo_icon.svg'
import ZedaLogo from '../assets/zeda_logo.png'
import PointerImage from '../assets/pointer.svg';
import styled from '@emotion/styled';
import ResultModal from './ResultModal'

const Container = styled.div`
padding-left: 48px;
padding-right: 48px;
padding-top: 16px;
height: calc(100vh - 16px);
overflow: hidden;
@media (max-width:1025px) {
    overflow-y: auto;
}
`;

const MainContentContainer = styled.div`
justify-content: center;
display: flex;
align-items: center;
height: calc(100% - 200px);
@media (max-width:1178px) {
    margin-top: 60px;
}

@media (max-width:1025px) {
    display: block;
}
`;

const WheelContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
position: relative;
margin-top: 80px;
@media (max-width:1025px) {
    margin-bottom: 24px;
    margin-top: 0px;
}

>div {
    max-width:${(props) => (props.mustSpin ? '600px' : '500px')};
    max-height:${(props) => (props.mustSpin ? '600px' : '500px')};
    box-shadow: 0px 18px 21.6px -3.6px rgba(16, 24, 40, 0.08), 0px 7.2px 7.2px -3.6px rgba(16, 24, 40, 0.03); 
    border-radius: 50%;
    >img {
        width : 22%;
    }
}


`;

const StyledButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 100;
  height: 30%;
`;

const TextContainer = styled.div`
margin-right: 10%;
@media (max-width:1025px) {
    margin-bottom: 24px;
}
`;

const Line1 = styled.span`
color: #344054;
font-size: 48px;
font-weight: 500;
@media (max-width:556px) {
    font-size: 32px;
}
@media (max-width:412px) {
    font-size: 24px;
}
`;

const Line2 = styled.span`
color: #3F57DE;
font-size: 48px;
font-weight: 500;

@media (max-width:556px) {
    font-size: 32px;
}
@media (max-width:412px) {
    font-size: 24px;
}
`;

const Line3 = styled.span`
color: #667085;
font-size: 20px;
font-weight: 400;
display: block;
margin-top: 24px;
@media (max-width:556px) {
    font-size: 16px;
}
@media (max-width:412px) {
    font-size: 14px;
}
`;

const Line4 = styled.span`
color: #344054;
font-size: 20px;
font-weight: 500;
display: block;
margin-top: 24px;
@media (max-width:556px) {
    font-size: 16px;
}
@media (max-width:412px) {
    font-size: 14px;
}

`

const wheelData = [
    { option: 'Visionary 🚀 ', style: { backgroundColor: '#472EA3', textColor: 'white' }, title: 'You build products that solve real problems. 🚀', description: '', subDescription: '' },
    { option: 'Storyteller 💬', style: { backgroundColor: '#5E3FBE', textColor: 'white', }, title: 'With stories, you bring the product to life. ✨', description: 'With AI-Assist, you can craft release notes that connect with users 🙇', subDescription: '' },
    { option: 'Rainmaker 🤑', style: { backgroundColor: '#886BD8', textColor: 'white' }, title: 'You build products that generate revenue 💰', description: 'You can create & visualize product plans, link goals, add initiatives. Connect OKRs and measure the outcomes of every product you build.', subDescription: 'Create Product Strategies and Measure Outcomes 💯' },
    { option: 'Nerd 🤓', style: { backgroundColor: '#A88DEB', textColor: 'white' }, title: 'You use data to make better product decisions. 💯', description: 'You can integrate with your CRM, product analytics tool, and feedback platforms to translate qualitative and quantitative data into actionable product insights.', subDescription: '' },
    { option: 'Empath 🤍', style: { backgroundColor: '#CBB6F8', textColor: '#101828' }, title: 'You understand user’s needs and build products around them. 🙇', description: 'You can capture and centralize customer and product feedback, uncover burning issues, and discover product opportunities. ', subDescription: 'No one knows your customers more than you 💜' },
]

const SpinnerWheel = (props) => {
    const audio = new Audio(wheelSound)
    const [mustSpin, setMustSpin] = useState(false)
    const [prizeNumber, setPrizeNumber] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [priceCount, setPriceCount] = useState(0);

    const dataWeight = { 0: 0.1, 1: 0.225, 2: 0.225, 3: 0.225, 4: 0.225 };
    const afterJackpot = { 0: 0, 1: 0.25, 2: 0.25, 3: 0.25, 4: 0.25 };

    const generateWeightedRandom = (weights) => {
        const totalWeight = Object.values(weights).reduce((sum, weight) => sum + weight, 0);

        // Generate a random value between 0 and the total weight
        const randomValue = Math.random() * totalWeight;

        // Iterate through the weights to find the corresponding number
        let cumulativeWeight = 0;
        for (const [i, weight] of Object.entries(weights)) {
            cumulativeWeight += weight;
            if (randomValue <= cumulativeWeight) {
                return i; // Return the selected number
            }
        }
    }

    const toggle = () => {
        setIsOpen((prev) => !prev)
    }

    const fetchWinners = async () => {
        const jackpotPriceInfo = await fetch('https://spin-the-wheel-f544a-default-rtdb.firebaseio.com/jackpot.json')
            .then(response => {
                return response.json()
            })
            .then(data => {
                // Work with JSON data here
                return data;
            }, error => {
                console.error('onRejected function called: ' + error.message);
            })

        setPriceCount(jackpotPriceInfo?.count || 0);

    }

    useEffect(() => {
        fetchWinners()
    }, [])

    const handleSpinClick = async () => {
        if (!mustSpin) {
            const newPrizeNumber = generateWeightedRandom(priceCount === 10 ? afterJackpot : dataWeight)
            setPrizeNumber(newPrizeNumber)
            audio.play()
            setMustSpin(true)
            setTimeout(function () {
                setIsOpen(true)

            }, 5550)
        }

    }

    const handleStopSpin = async () => {
        const body = {
            ...props.userInfo, prize: wheelData[prizeNumber].option
        }
        await fetch('https://spin-the-wheel-f544a-default-rtdb.firebaseio.com/spinwheel.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        if (prizeNumber === '0') {
            await fetch('https://spin-the-wheel-f544a-default-rtdb.firebaseio.com/jackpot.json', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ count: priceCount + 1 })
            })
            setPriceCount((prev) => prev + 1)
        }

    }
    return (
        <Container>
            <a href='https://zeda.io/' target="_blank" rel="noreferrer">
                <img
                    src={ZedaLogo}
                    alt="ZedaLogo"
                    height={46}
                    width={120}
                    margin={{ top: 16, left: 16, bottom: 8 }}
                />
            </a>
            <MainContentContainer>
                {!mustSpin && <TextContainer>
                    <Line1>The Great</Line1>
                    <Line2 > PM Skill-off</Line2>
                    <Line3>Product Manager at any given point wears multiple hats, spin to see which one hits home and get a chance to win the Jackpot! ✨</Line3>
                    <Line4>Which hat are you wearing today??? 🎩</Line4>
                </TextContainer>}
                <WheelContainer mustSpin={mustSpin}>
                    <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={wheelData}
                        onStopSpinning={() => {
                            audio.pause()
                            setMustSpin(false)
                            handleStopSpin();
                        }}
                        disableInitialAnimation={true}
                        spinDuration={0.4}
                        outerBorderColor='#F2F4F7'
                        outerBorderWidth={0}
                        radiusLineColor='white'
                        fontSize={18}
                        fontWeight={500}
                        textDistance={66}
                        innerRadius={10}
                        pointerProps={{ src: PointerImage }}


                    />
                    <StyledButton src={ZedaIcon} onClick={handleSpinClick} />
                </WheelContainer>
            </MainContentContainer>
            <ResultModal isOpen={isOpen} toggle={toggle} content={wheelData[prizeNumber]} />
        </Container>
    )
}

export default SpinnerWheel;